<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('signup.title') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps">
              <span class="current">{{ $t('signup.step') }} 3</span>
              <span class="total">{{$t('signup.out-of') }} 3</span>
            </div>
            <div class="signup-by-iin-main">
              <div class="iin-left">
                <form-group
                    :field="$v.teacher.last_name"
                    :label="$t('profile.last-name-label') + $t('references.required-field')"
                    name="last_name"
                >
                  <el-input
                      :placeholder="$t('profile.last-name-placeholder')"
                      v-model="teacher.last_name"
                      @change="$v.teacher.last_name.$touch()"
                  />
                </form-group>
                <form-group
                    :field="$v.teacher.first_name"
                    :label="$t('profile.first-name-label') + $t('references.required-field')"
                    name="first_name"
                >
                  <el-input
                      clearable
                      :placeholder="$t('profile.first-name-placeholder')"
                      v-model="teacher.first_name"
                      @change="$v.teacher.first_name.$touch()"
                  />
                </form-group>
                <form-group
                    :field="$v.teacher.patronymic"
                    :label="$t('profile.patronymic-label')"
                    name="patronymic"
                >
                  <el-input
                      clearable
                      :placeholder="$t('profile.patronymic-placeholder')"
                      v-model="teacher.patronymic"
                  />
                </form-group>
                <form-group
                    :field="$v.teacher.phone"
                    :label="$t('references.phone-label') + $t('references.required-field')"
                    name="phone"
                >
                  <el-input
                      clearable
                      :placeholder="$t('references.phone-placeholder')"
                      v-model="teacher.phone"
                      v-mask="'+7 (###) ### ##-##'"
                      @change="$v.teacher.phone.$touch()"
                  />
                </form-group>
                <form-group
                    :field="$v.teacher.birth_date"
                    :label="$t('profile.birth-date-label') + $t('references.required-field')"
                    name="birth_date"
                >
                  <el-input
                      clearable
                      :placeholder="$t('profile.birth-date-label')"
                      v-model="teacher.birth_date"
                      v-mask="'##.##.####'"
                      @change="$v.teacher.birth_date.$touch()"
                  />
                </form-group>
                <form-group :field="$v.teacher.gender" :label="$t('references.gender') + $t('references.required-field')" name="gender">
                  <el-select v-model="teacher.gender" :placeholder="$t('references.gender')">
                    <el-option
                        v-for="iin in [{ value: 1, label: $t('references.gender-male') }, { value: 2, label: $t('references.gender-female') }]"
                        @change="$v.teacher.gender.$touch()"
                        :key="iin.value"
                        :label="iin.label"
                        :value="iin.value"
                    />
                  </el-select>
                </form-group>
                <form-group :field="$v.teacher.region_id" :label="$t('references.region-label') + $t('references.required-field')" name="region_id">
                  <el-select
                      v-model="teacher.region_id"
                      :placeholder="$t('references.region-placeholder')"
                      :loading="this.regions.loading"
                  >
                    <el-option
                        v-for="region in regions.data"
                        @change="$v.teacher.region_id.$touch()"
                        :key="region.id"
                        :label="region.name"
                        :value="region.id"
                    />
                  </el-select>
                </form-group>
                <form-group :field="$v.teacher.city_id" :label="$t('references.district-label') + $t('references.required-field')" name="city_id">
                  <el-select
                      v-model="teacher.city_id"
                      :placeholder="$t('references.district-label')"
                      :loading="this.cities.loading"
                      :disabled="!this.teacher.region_id"
                  >
                    <el-option
                        v-for="city in cities.data"
                        @change="$v.teacher.city_id.$touch()"
                        :key="city.id"
                        :label="city.name"
                        :value="city.id"
                    />
                  </el-select>
                </form-group>
                <form-group :field="$v.teacher.locality_id" :label="$t('references.locality-label') + $t('references.required-field')" name="locality_id">
                  <el-select
                      v-model="teacher.locality_id"
                      :placeholder="$t('references.locality-label')"
                      :disabled="!this.teacher.city_id"
                      :loading="this.localities.loading"
                  >
                    <el-option
                        v-for="locality in localities.data"
                        @change="$v.teacher.locality_id.$touch()"
                        :key="locality.id"
                        :label="locality.name"
                        :value="locality.id"
                    />
                  </el-select>
                </form-group>
                <form-group :field="$v.teacher.school_id" :label="$t('references.school-label') + $t('references.required-field')" name="school_id">
                  <el-select
                      v-model="teacher.school_id"
                      :placeholder="$t('references.school-label')"
                      :loading="this.schools.loading"
                      :disabled="!this.teacher.locality_id"
                  >
                    <el-option
                        v-for="school in schools.data"
                        @change="$v.teacher.school_id.$touch()"
                        :key="school.id"
                        :label="school.name"
                        :value="school.id"
                    />
                  </el-select>
                </form-group>
                <form-group :field="$v.teacher.subject_id" :label="$t('subject_of_teaching') + $t('references.required-field')">
                  <el-select v-model="teacher.subject_id" :placeholder="$t('subject_of_teaching')">
                    <el-option
                        v-for="item in subjects.data"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                </form-group>
                <form-group :field="$v.teacher.teaching_language_id" :label="$t('education-language-label-teacher') + $t('references.required-field')">
                  <el-select v-model="teacher.teaching_language_id" :placeholder="$t('education-language-label-teacher')">
                    <el-option
                        v-for="item in teachingLanguages.data"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    />
                  </el-select>
                </form-group>
                <form-group :field="$v.teacher.password" :label="$t('signin-form.password-placeholder') + $t('references.required-field')">
                  <el-input
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      maxlength="8"
                      show-password
                      type="number"
                      :placeholder="$t('signin-form.password-placeholder')"
                      @input="onPasswordInput"
                      v-model="teacher.password"
                      @change="$v.teacher.password.$touch()"
                      clearable
                  />
                </form-group>
                <div class="password-hint" v-if="showPassHint">
                  <div>{{ $t('signin-form.password-must1') }}</div>
                  <div>{{ $t('signin-form.password-must2') }}</div>
                  <div>{{ $t('signin-form.password-must3') }}</div>
                </div>
                <div class="button-iin-signup-group">
                  <el-button type="button" class="button-iin-next" @click="submit">
                    {{ $t('signup.next-btn') }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="finish-modal" hide-footer hide-header>
      <div id="finish-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('finish-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <svg width="257" height="220" viewBox="0 0 257 220" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M229.734 162.75H214.766C214.067 162.75 213.5 163.317 213.5 164.016L213.5 178.984C213.5 179.683 214.067 180.25 214.766 180.25H229.734C230.433 180.25 231 179.683 231 178.984V164.016C231 163.317 230.433 162.75 229.734 162.75Z"
                fill="#597EF7"/>
            <path
                d="M55.6719 30.8125H28.8282C28.1292 30.8125 27.5625 31.3791 27.5625 32.0781L27.5625 58.9219C27.5625 59.6209 28.1291 60.1875 28.8281 60.1875H55.6719C56.3709 60.1875 56.9375 59.6209 56.9375 58.9219V32.0781C56.9375 31.3791 56.3709 30.8125 55.6719 30.8125Z"
                fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M119.907 179.384H145.381V159.369H119.907V179.384Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M160.815 186.239H105.32C103.058 186.239 101.226 184.406 101.226 182.145C101.226 179.884 103.058 178.051 105.32 178.051H160.815C163.077 178.051 164.909 179.884 164.909 182.145C164.909 184.406 163.077 186.239 160.815 186.239Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M218.23 160.669H45.8393C41.6073 160.669 38.1768 157.239 38.1768 153.007V47.9389C38.1768 43.7069 41.6073 40.2764 45.8393 40.2764H218.23C222.462 40.2764 225.892 43.7069 225.892 47.9389V153.007C225.892 157.239 222.462 160.669 218.23 160.669Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M185.707 70.3541H79.8643V63.0547C79.8643 61.4713 81.1532 60.1875 82.743 60.1875H182.828C184.418 60.1875 185.707 61.4713 185.707 63.0547V70.3541Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M87.2862 64.961C87.2862 65.9548 86.4823 66.7604 85.4906 66.7604C84.4991 66.7604 83.6953 65.9548 83.6953 64.961C83.6953 63.9673 84.4991 63.1616 85.4906 63.1616C86.4823 63.1616 87.2862 63.9673 87.2862 64.961Z"
                  fill="#FF7946"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M93.9892 64.961C93.9892 65.9548 93.1854 66.7604 92.1938 66.7604C91.2022 66.7604 90.3984 65.9548 90.3984 64.961C90.3984 63.9673 91.2022 63.1616 92.1938 63.1616C93.1854 63.1616 93.9892 63.9673 93.9892 64.961Z"
                  fill="#FAAD14"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M100.693 64.961C100.693 65.9548 99.8895 66.7604 98.8979 66.7604C97.9063 66.7604 97.1025 65.9548 97.1025 64.961C97.1025 63.9673 97.9063 63.1616 98.8979 63.1616C99.8895 63.1616 100.693 63.9673 100.693 64.961Z"
                  fill="#7CB305"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M82.9637 140.72C81.1933 140.72 79.7578 139.438 79.7578 137.857V69.6802H185.606V137.857C185.606 139.438 184.17 140.72 182.4 140.72H82.9637Z"
                  fill="#E4EBF7"/>
            <path
                d="M177.335 74.9082H87.6886C86.9831 74.9082 86.4111 75.4802 86.4111 76.1857V133.514C86.4111 134.22 86.9831 134.792 87.6886 134.792H177.335C178.041 134.792 178.613 134.22 178.613 133.514V76.1857C178.613 75.4802 178.041 74.9082 177.335 74.9082Z"
                fill="white"/>
            <path
                d="M130.612 83.4629H97.3431C96.0307 83.4629 94.9668 84.5268 94.9668 85.8392C94.9668 87.1516 96.0307 88.2156 97.3431 88.2156H130.612C131.924 88.2156 132.988 87.1516 132.988 85.8392C132.988 84.5268 131.924 83.4629 130.612 83.4629Z"
                fill="#FF7946"/>
            <path
                d="M159.128 111.979H97.3431C96.0307 111.979 94.9668 113.043 94.9668 114.355C94.9668 115.668 96.0307 116.732 97.3431 116.732H159.128C160.44 116.732 161.504 115.668 161.504 114.355C161.504 113.043 160.44 111.979 159.128 111.979Z"
                fill="#ADC6FF"/>
            <path
                d="M149.622 121.484H97.3431C96.0307 121.484 94.9668 122.548 94.9668 123.861C94.9668 125.173 96.0307 126.237 97.3431 126.237H149.622C150.935 126.237 151.999 125.173 151.999 123.861C151.999 122.548 150.935 121.484 149.622 121.484Z"
                fill="#7CB305"/>
            <path
                d="M140.117 102.474H97.3431C96.0307 102.474 94.9668 103.538 94.9668 104.85C94.9668 106.162 96.0307 107.226 97.3431 107.226H140.117C141.43 107.226 142.493 106.162 142.493 104.85C142.493 103.538 141.43 102.474 140.117 102.474Z"
                fill="#FAAD14"/>
            <path
                d="M168.633 92.9683H97.3431C96.0307 92.9683 94.9668 94.0322 94.9668 95.3446C94.9668 96.657 96.0307 97.7209 97.3431 97.7209H168.633C169.945 97.7209 171.009 96.657 171.009 95.3446C171.009 94.0322 169.945 92.9683 168.633 92.9683Z"
                fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M43.9781 195.051H43.0893C41.8674 195.051 40.8672 194.054 40.8672 192.834V175.099C40.8672 173.879 41.8674 172.882 43.0893 172.882H43.9781C45.2 172.882 46.2002 173.879 46.2002 175.099V192.834C46.2002 194.054 45.2 195.051 43.9781 195.051Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M70.3858 195.714C70.3858 197.183 69.1921 198.374 67.7193 198.374H19.7251C18.2523 198.374 17.0586 197.183 17.0586 195.714C17.0586 194.245 18.2523 193.053 19.7251 193.053H67.7193C69.1921 193.053 70.3858 194.245 70.3858 195.714Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M35.9519 200.208C35.2187 200.208 34.6191 199.609 34.6191 198.878V192.549C34.6191 191.818 35.2187 191.22 35.9519 191.22H51.1159C51.849 191.22 52.4491 191.818 52.4491 192.549V198.878C52.4491 199.609 51.849 200.208 51.1159 200.208H35.9519Z"
                  fill="#ADC6FF"/>
            <path d="M36.834 192.53V198.897" stroke="#597EF7" stroke-width="6" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M50.0127 192.53V198.897" stroke="#597EF7" stroke-width="6" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7227 183.15H68.7213V124.878H18.7227V183.15Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.4981 183.653C17.2465 183.653 16.2227 182.631 16.2227 181.383V167.964C16.2227 166.716 17.2465 165.694 18.4981 165.694H68.9462C70.1973 165.694 71.2217 166.716 71.2217 167.964V181.383C71.2217 182.631 70.1973 183.653 68.9462 183.653H18.4981Z"
                  fill="#ADC6FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.4981 141.727C17.2465 141.727 16.2227 140.705 16.2227 139.456V126.038C16.2227 124.79 17.2465 123.768 18.4981 123.768H68.9462C70.1973 123.768 71.2217 124.79 71.2217 126.038V139.456C71.2217 140.705 70.1973 141.727 68.9462 141.727H18.4981Z"
                  fill="#ADC6FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.5977 134.449C21.6555 134.449 20.8916 133.687 20.8916 132.747C20.8916 131.807 21.6555 131.044 22.5977 131.044C23.5404 131.044 24.3043 131.807 24.3043 132.747C24.3043 133.687 23.5404 134.449 22.5977 134.449Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M26.1504 132.747C26.1504 131.807 26.9143 131.044 27.857 131.044C28.7992 131.044 29.5636 131.807 29.5636 132.747C29.5636 133.687 28.7992 134.449 27.857 134.449C26.9143 134.449 26.1504 133.687 26.1504 132.747Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.1168 134.449C32.174 134.449 31.4102 133.687 31.4102 132.747C31.4102 131.807 32.174 131.044 33.1168 131.044C34.059 131.044 34.8229 131.807 34.8229 132.747C34.8229 133.687 34.059 134.449 33.1168 134.449Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M51.2275 134.449C50.9675 134.449 50.7568 134.239 50.7568 133.98V131.514C50.7568 131.255 50.9675 131.044 51.2275 131.044H67.3503C67.6103 131.044 67.8209 131.255 67.8209 131.514V133.98C67.8209 134.239 67.6103 134.449 67.3503 134.449H51.2275Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.4981 162.69C17.2465 162.69 16.2227 161.668 16.2227 160.419V147.001C16.2227 145.753 17.2465 144.73 18.4981 144.73H68.9462C70.1973 144.73 71.2217 145.753 71.2217 147.001V160.419C71.2217 161.668 70.1973 162.69 68.9462 162.69H18.4981Z"
                  fill="#ADC6FF"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M22.5977 156.021C21.6555 156.021 20.8916 155.259 20.8916 154.318C20.8916 153.378 21.6555 152.616 22.5977 152.616C23.5404 152.616 24.3043 153.378 24.3043 154.318C24.3043 155.259 23.5404 156.021 22.5977 156.021Z"
                  fill="#FF7946"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.857 156.021C26.9143 156.021 26.1504 155.259 26.1504 154.318C26.1504 153.378 26.9143 152.616 27.857 152.616C28.7992 152.616 29.5636 153.378 29.5636 154.318C29.5636 155.259 28.7992 156.021 27.857 156.021Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.1168 156.021C32.174 156.021 31.4102 155.259 31.4102 154.318C31.4102 153.378 32.174 152.616 33.1168 152.616C34.059 152.616 34.8229 153.378 34.8229 154.318C34.8229 155.259 34.059 156.021 33.1168 156.021Z"
                  fill="#253368"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M67.2521 156.021H51.3257C51.0113 156.021 50.7568 155.767 50.7568 155.454V153.183C50.7568 152.87 51.0113 152.616 51.3257 152.616H67.2521C67.5665 152.616 67.8209 152.87 67.8209 153.183V155.454C67.8209 155.767 67.5665 156.021 67.2521 156.021Z"
                  fill="#FF7946"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M20.8916 174.799C20.8916 173.859 21.6555 173.097 22.5977 173.097C23.5404 173.097 24.3043 173.859 24.3043 174.799C24.3043 175.74 23.5404 176.502 22.5977 176.502C21.6555 176.502 20.8916 175.74 20.8916 174.799Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.857 176.502C26.9143 176.502 26.1504 175.74 26.1504 174.799C26.1504 173.859 26.9143 173.097 27.857 173.097C28.7992 173.097 29.5636 173.859 29.5636 174.799C29.5636 175.74 28.7992 176.502 27.857 176.502Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M33.1168 176.502C32.174 176.502 31.4102 175.74 31.4102 174.799C31.4102 173.859 32.174 173.097 33.1168 173.097C34.059 173.097 34.8229 173.859 34.8229 174.799C34.8229 175.74 34.059 176.502 33.1168 176.502Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M67.2521 176.502H51.3257C51.0113 176.502 50.7568 176.248 50.7568 175.935V173.664C50.7568 173.351 51.0113 173.097 51.3257 173.097H67.2521C67.5665 173.097 67.8209 173.351 67.8209 173.664V175.935C67.8209 176.248 67.5665 176.502 67.2521 176.502Z"
                  fill="#597EF7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M210.25 7.26562C188.581 7.26562 171.016 24.8314 171.016 46.5C171.016 68.1685 188.581 85.7344 210.25 85.7344C231.919 85.7344 249.484 68.1685 249.484 46.5C249.484 24.8314 231.919 7.26562 210.25 7.26562Z"
                  fill="#399300"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M203.928 59.8274L201.451 57.3504L225.454 33.2597C226.275 32.4388 227.606 32.4388 228.427 33.2597L229.418 34.2504C230.238 35.0713 230.238 36.4021 229.418 37.223L206.901 59.8274C206.08 60.6483 204.749 60.6483 203.928 59.8274Z"
                  fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M209.373 57.3505L206.896 59.8275C206.075 60.6484 204.744 60.6484 203.923 59.8275L194.06 49.9641C193.239 49.1433 193.239 47.8125 194.06 46.9917L195.051 46.0009C195.872 45.18 197.202 45.18 198.023 46.0009L209.373 57.3505Z"
                  fill="white"/>
          </svg>
          <h3 class="text-success">{{ $t('signup.finish-success-text-teacher') }}</h3>
          <!--          <span class="description" v-html="$t('signup.finish-succes-status-new')"></span>-->
          <div class="text-center pt-3">
            <router-link class="btn btn-primary" to="/teacher-cabinet">{{ $t('signup.finish-resume-btn') }}</router-link>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { minLength, required } from "vuelidate/lib/validators"
import VueMask from 'v-mask'
import moment from 'moment';

export default {
  name: 'TeacherSignupStep3',
  data() {
    return {
      showPassHint: false,
      teacherId: null,
      regions: {
        loading: false,
        data: [],
      },
      cities: {
        loading: false,
        data: [],
      },
      localities: {
        loading: false,
        data: [],
      },
      schools: {
        loading: false,
        data: [],
      },
      subjects: {
        loading: false,
        data: [],
      },
      teachingLanguages: {
        loading: false,
        data: [],
      },
      teacher: {
        last_name: '', // Фамилия (обязательное поле для ввода)
        first_name: '', // Имя (обязательное поле для ввода)
        patronymic: '', // Отчество (поле для ввода)
        phone: '', // Номер телефона (обязательное поле для ввода с маской)
        birth_date: '', // Дата рождения - по идее можем вытащить просто с ИИН. (форма должна быть удобной, чтобы выбирать год или вводить - НЕ СКРОЛ, так как учителя 1960-2004 года рождения) - (обязательно к заполнению)
        gender: '', // Пол - дропдаун - обязательный выбор одного значения
        region_id: '', // Область - дропдаун - обязательный выбор одного значения
        city_id: '', // Район - дропдаун с поиском - обязательный выбор одного значения
        locality_id: '', // Населенный пункт - дропдаун с поиском - обязательный выбор одного значения
        school_id: '', // Школа - дропдаун с поиском - обязательный выбор одного значения
        password: '', // Установка пароля с подсказками по условиям и с глазиком для просмотра введенных данных (только цифры и минимум 8 цифр). Как тут, при нажатии на поле ввода/установки пароля выводим подсказку сразу
      }
    }
  },
  validations: {
    teacher: {
      last_name: { required },
      first_name: { required },
      phone: { required },
      birth_date: { required },
      gender: { required },
      region_id: { required },
      city_id: { required },
      locality_id: { required },
      school_id: { required },
      password: { required, minLength: minLength(8) },
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$http.post(`${window.API_ROOT}/api/teacher/register/finish/${this.teacherId}`, {
        ...this.teacher,
        phone: this.teacher.phone
            .replace(/\D/g, "")
            .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)[0],
        birth_date: moment(this.teacher.birth_date, 'DD.MM.YYYY').format("YYYY-MM-DD"),
      })
          .then((res) => {
            localStorage.setItem('token', res.body.token)
            localStorage.setItem('user', JSON.stringify(res.body.user))
            this.$bvModal.show('finish-modal');
          })
          .catch((e) => {
            console.log(e);
            Vue.toastr({
              message: e.body.message,
              type: 'error'
            });
          });
    },
    onPasswordInput(e) {
      this.showPassHint = true;

      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        });
      }

      this.teacher.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    enforcePhoneFormat() {
      this.$v.teacher.phone.$touch();
      let x = this.teacher.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.teacher.phone = x[0];
    },
    getRegions() {
      this.regions.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/regions`)
          .then((res) => {
            if (res.body.data) {
              this.regions.loading = false;
              this.regions.data = res.body.data;
            }
          })
          .catch((e) => {
            this.regions.loading = false;
            console.log(e);
          });
    },
    getDistricts(regionId) {
      this.cities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/districts/${regionId}`)
          .then((res) => {
            if (res.body.data) {
              this.cities.loading = false;
              this.cities.data = res.body.data;
            }
          })
          .catch((e) => {
            this.cities.loading = false;
          });
    },
    getLocalities(districtId) {
      this.localities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/localities/${districtId}`)
          .then((res) => {
            if (res.body.data) {
              this.localities.loading = false;
              this.localities.data = res.body.data;
            }
          })
          .catch((e) => {
            this.localities.loading = false;
          });
    },
    getSchools(localityId, query) {
      this.schools.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/schools/${localityId}`, { params: { q: query } })
          .then((res) => {
            if (res.body.data) {
              this.schools.loading = false;
              this.schools.data = res.body.data;
            }
          })
          .catch((e) => {
            this.schools.loading = false;
          });
    },
    getSubjects() {
      this.subjects.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/subjects/`)
          .then((res) => {
            if (res.body.data) {
              this.subjects.loading = false;
              this.subjects.data = res.body.data;
            }
          })
          .catch((e) => {
            this.subjects.loading = false;
          });
    },
    getTeachingLanguages() {
      this.teachingLanguages.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/teaching-languages/`)
          .then((res) => {
            if (res.body.data) {
              this.teachingLanguages.loading = false;
              this.teachingLanguages.data = res.body.data;
            }
          })
          .catch((e) => {
            this.teachingLanguages.loading = false;
          });
    }
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  beforeMount() {
    const teacherData = JSON.parse(localStorage.getItem('teacherSignUpData') || {})
    this.teacherId = teacherData.id;
  },
  mounted() {
    this.getRegions();
    this.getSubjects();
    this.getTeachingLanguages();
  },
  watch: {
    'teacher.region_id': function(regionId) {
      this.getDistricts(regionId);
      this.teacher.city_id = '';
      this.teacher.locality_id = '';
      this.teacher.school_id = '';
    },
    'teacher.city_id': function(cityId) {
      this.getLocalities(cityId);
      this.teacher.locality_id = '';
      this.teacher.school_id = '';
    },
    'teacher.locality_id': function(localityId) {
      this.getSchools(localityId);
      this.teacher.school_id = '';
    },
  }
}
</script>

<style lang="css" scoped>
.modal-dialog {
  max-width: max-content;
  width: max-content;
}

@media screen and (max-width: 991px) {
  .signup-by-iin-main {
    flex-direction: column;
  }

  .iin-left {
    width: 100%;
  }

  .iin-info {
    width: 100%;
  }

  .iin-wrap-info {
    height: 482px;
  }

  .button-iin-signup-group {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 575px) {
  .download-instruction {
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .watch-instruction {
    width: 100%;
    margin-left: 0;
  }

  .button-iin-signup-group {
    flex-direction: column;
  }

  .button-iin-next {
    margin-right: 0;
    display: block;
    width: 100%;
  }

  .button-iin-clear {
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }

  .iin-search-item {
    font-size: 14px;
  }

  .iin-info {
    padding: 10px;
  }

  .warning-message {
    width: 64%;
  }
}
</style>

